.footer-container {
  width: 100%;
  background-color: #263238;
  padding: 1rem;
  min-height: 235px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.footer-navbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.footer-navbar-logo img {
  height: 50px;
}

.footer-navbar-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 1rem;
}

.footer-navbar-menu li {
  list-style: none;
}

.footer-navbar-menu .menuItem {
  color: white;
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
}

.footer-navbar-menu .menuItem:hover {
  color: #ff9800;
}

.footer-image {
  height: 60px;
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 2rem;
  width: 100%;
}

.footer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  opacity: 0.8;
  text-align: center;
}

.footer-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.footer-icon-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #263238;
  color: white;
  border-radius: 0.25rem;
  text-decoration: none;
}

.footer-icon-link img {
  height: 1.8rem;
  opacity: 0.8;
}

@media (min-width: 768px) {
  .footer-navbar {
    flex-direction: row;
  }

  .footer-navbar-menu {
    flex-direction: row;
  }

  .footer-grid {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10rem;
    margin-top: 6rem;
  }
}



.ej-slide {
  background-image: url('https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_2000/canada3_ydiycg');
  background-size: cover;
  background-position: center;
}

.ej-slide-content-container {
  background: rgba(7, 5, 5, 0.9);
  height: 70vh;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.cat {
  max-width: 90%; /* Adjusted for better responsiveness */
  margin: 0 auto; /* Centering the container */
}

.ej-heading-title {
  font-family: "Poppins", Sans-serif;
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 1.5; /* Adjusted for better readability */
}

.ej-subtitle {
  font-size: 1.25rem !important; /* Adjusted to use rem units */
  line-height: 2rem !important;
  font-weight: lighter !important;

}

.ej-button-content-wrapper {
  display: flex;
  justify-content: center;
}

.ej-button {
  display: inline-block;
  line-height: 1;
  background-color: #1D99C4;
  font-size: 1rem; /* Adjusted to use rem units */
  padding: 12px 24px;
  border-radius: 3px;
  color: #fff;
  fill: #fff;
  text-align: center;
  transition: all .3s;
}

.ej-button.ej-size-lg {
  font-size: 1.125rem; /* Adjusted to use rem units */
  padding: 20px 40px;
  border-radius: 0px;
}

.ej-button-content-wrapper {
  display: flex;
  justify-content: center;
}

.ej-button-text {
  flex-grow: 1;
  order: 10;
  display: inline-block;
}

.ej-footer-copy-text {
  /* font-family: "Poppins", Sans-serif; */
  font-size: 0.9rem;
  font-weight: normal;
  opacity: 0.8;
}

.ej-icon {
  height: 2rem;
  opacity: 0.8;
}

@media (max-width: 500px) {
  .ej-heading-title {
    font-size: 1.6rem !important;
    line-height: 1.2 !important;
  }
  .ej-subtitle {
    font-size: 1rem !important;
    line-height: 23px!important;
    /* font-weight: lighter!important; */
    /* padding: 10px 0px 10px 10px; */
  }
  .ej-button {
    font-size: 0.875rem;
    padding: 10px 20px;
  }
}

@media (max-width: 767px) {
  .ej-heading-title {
    font-size: 2rem;
    line-height: 1.4em;
  }
  /* .ej-subtitle {
    font-size: 1.1rem !important;
    padding: 10px 0px 10px 30px;
  } */
  .ej-button {
    font-size: 0.9375rem;
    padding: 10px 24px;
  }
}

@media (max-width: 1024px) {
  .ej-heading-title {
    font-size: 2.5rem;
    line-height: 1.3em;
  }
  /* .ej-subtitle {
    font-size: 1.15rem !important;
    padding: 10px 0px 10px 35px;
  } */
  .ej-button {
    font-size: 1rem;
    padding: 12px 28px;
  }
}


/* Add these styles in your Tailwind CSS configuration or a custom CSS file */

.bg-dark {
  background-color: #1a1a1a; /* Adjust to match the screenshot */
}

.text-light {
  color: #ffffff; /* Adjust to match the screenshot */
}

.bg-dark-secondary {
  background-color: #2a2a2a; /* Adjust to match the screenshot */
}

.border-gray-600 {
  border-color: #4a4a4a; /* Adjust to match the screenshot */
}

.text-gray-400 {
  color: #b0b0b0; /* Adjust to match the screenshot */
}

.hover\:text-light:hover {
  color: #ffffff; /* Adjust to match the screenshot */
}

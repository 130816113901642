.carousel-custom-height {
  height: 100vh;
}

.Image {
  height: 100vh;
}

.book {
  border: white!important;
}

.elementor-heading-title {
  color: #263238;
  font-family: "Poppins", Sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
}

.service-item-heading-title {
  color: #263238;
  font-family: "Poppins", Sans-serif;
  font-size: 22px;
  font-weight: 600;
}

.service-item-content {
  font-size: 16px !important;
  line-height: 1.7rem;
  font-weight: normal !important;
  color: #666666;
  font-family: "Poppins", Sans-serif;
}

.service-last-p {
  font-family: "Poppins", Sans-serif;
  font-size: 1rem;
  line-height: 2.3rem;
  font-weight: 300;
}

.box-shadow-i {
  box-shadow: 0px 0px 8px 0px rgb(0, 0, 0, 0.1);
}

.elementor-button-content-wrapper {
  display: flex;
  justify-content: center;
}

.elementor-button {
  display: inline-block;
  line-height: 1;
  background-color: #1d99c4;
  font-size: 15px;
  padding: 12px 24px;
  border-radius: 3px;
  color: #fff;
  fill: #fff;
  text-align: center;
  transition: all 0.3s;
}

.elementor-button.elementor-size-lg {
  font-size: 18px;
  padding: 20px 40px;
  border-radius: 0px;
}

.elementor-button-content-wrapper {
  display: flex;
  justify-content: center;
}

.elementor-button-text {
  flex-grow: 1;
  order: 10;
  display: inline-block;
}

.inner svg {
  color: red !important;
}

.carousel-container {
  position: absolute;
  z-index: 1; 
}

.services-section {
  position: relative;
  z-index: 0;
  margin-top: 680px;
}

.carousel-custom-height {
  height: 100vh; 
}


@keyframes fadeUp {
  to {
    top: 0;
  }
}

/* .slide {
  background-image: url("/public/images/canada0.jpg");
  background-size: cover;
  background-position: center top;
}

.slide-content-container {
  background: rgba(7, 5, 5, 0.8);
  height: 89vh;
} */

.color {
  font-size: 3.1rem;
  font-weight: 700;
  line-height: 4.1875rem;
  color: #fff !important;
}

.colorr {
  color: white;
}

.subtitle {
  font-size: 24px !important;
  line-height: normal !important;
  font-weight: normal !important;
}

.elementor-button-content-wrapper {
  display: flex;
  justify-content: center;
}

.elementor-button {
  display: inline-block;
  line-height: 1;
  background-color: #1d99c4;
  font-size: 15px;
  padding: 12px 24px;
  border-radius: 3px;
  color: #fff;
  fill: #fff;
  text-align: center;
  transition: all 0.3s;
}

.elementor-button.elementor-size-lg {
  font-size: 18px;
  padding: 20px 40px;
  border-radius: 0px;
}

.elementor-button-content-wrapper {
  display: flex;
  justify-content: center;
}

.elementor-button-text {
  flex-grow: 1;
  order: 10;
  display: inline-block;
}

.footer-copy-text {
  font-family: "Poppins", Sans-serif;
  font-size: 0.9rem;
  font-weight: normal;
  opacity: 0.8;
}

.elementor-icon {
  height: 2rem;
  opacity: 0.8;
}

@media (max-width: 500px) {
  .color {
    font-size: 30px !important;
    line-height: normal !important;
  }
  .colorr {
    font-size: 20px !important;
    line-height: normal !important;
  }
}

@media (max-width: 767px) {
  .color {
    font-size: 30px!important;
    line-height: 1.5em;
  }
  .colorr {
    font-size: 20px!important;
    line-height: 1.5em;
  }
  .services-section {
    margin-top: 700px!important;
  }
}

@media (max-width: 1024px) {
  .color {
    font-size: 44px;
    line-height: 1.3em;
  }
}

.navbar-scroll {
  margin-top: 0 !important;
  position: fixed;
  color: white;
  width: 100%;
  top: -100px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.805);
  /* background-color: black; */
  backdrop-filter: blur(3px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeUp 0.5s forwards;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.navbar-scroll .book {
  border: 1px solid #1d99c4;
  color: #1d99c4;
  padding: 10px;
  text-decoration: none;
}

.test {
  backdrop-filter: blur(30px);
  background-color: rgb(0, 0, 0);
}

.header {
  margin-top: 50px;
}

@media (max-width: 1023px) {
    .header {
      margin-top: 0;
    }
    .topHeader {
        display: none;
    }
  }




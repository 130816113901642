/* body {
    background: linear-gradient(to right, #fff9f9, white);
} */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: linear-gradient(to right, #00000008, white);
}



.hero-container {
  position: relative;
  height: 55vh;
  width: 100%;
}

.hero-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hero-container .absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* backdrop-filter: blur(3px)!important; */
}

.parallax {
    position: relative;
    background-image: url("https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/canada3_ydiycg");
    min-height: 400px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    z-index: 0;
  }
  
  .parallax::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: -1;
  }
  
  .parallax-content {
    position: relative;
    z-index: 1;
  }

  .map {
    border: none;
    width: 100%;
    height: 700px;
    padding: 50px;
  }

  .try {
    margin-top: 20px!important;
  }
  

@media (max-width: 1103px) {
  .custom-hidden {
    display: none;
  }
  .custom-full-width {
    width: 100%;
  }
  .custom-text-center {
    text-align: center;
  }
  .custom-divider-center {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .change {
    margin-top: 30px;
  }
  .map {
    padding: 0;
    margin-bottom: 60px;
    border: none;
  }
}
